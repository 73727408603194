@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
/* @font-face {
    
    font-family: effra;
    src: url("assets/fonts/Effra_Std_Lt.ttf");
    font-weight: lighter;
}

@font-face {
    font-family: effra;
    src: url("assets/fonts/Effra_Std_Rg.ttf");
    font-weight: normal;
}
 */

:root {
    --grey: #38434E;
    --grey-light: #909AA7;
    --pink: #E3B1F1;
    --blue: #226A84;
}

html,
body {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    overflow-x: hidden;
}

a,
a:active,
a:focus,
a:hover {
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
}

.font-accent {
    color: var(--pink);
}

.font-normal {
    color: var(--grey);
}

.font-light {
    color: var(--grey-light);
}

.font-blue {
    color: var(--blue) !important;
}

.font-green {
    color: #04A777;
}

.font-red {
    color: #D72638;
}

.font-pink {
    color: #B331DD;
}

.font-yellow {
    color: #F2C12E;
}

.font-orange {
    color: #DF882D;
}

.bg-accent {
    background: var(--pink) !important;
}

.bg-grey {
    background: #F8FAFF
}

.bg-normal {
    background: var(--grey) !important;
}

.bg-light {
    background: var(--grey-light) !important;
}

.bg-blue {
    background: var(--blue) !important;
}

.bg-green {
    background: #04A777 !important;
}

.bg-red {
    background: #D72638 !important;
}

.bg-pink {
    background: #B331DD !important;
}

.bg-yellow {
    background: #F2C12E !important;
}

.bg-orange {
    background: #DF882D !important;
}

.small-icon {
    height: 12px;
}

.font-small {
    font-size: 14px;
}

.bold {
    font-weight: 700;
}

.btn-outline-dandi,
.btn-outline-dandi:hover {
    border-radius: 8px;
    color: var(--blue);
    border: 3px solid var(--blue);
    text-transform: uppercase;
    font-weight: 700;
    min-height: 50px;
}

.btn-dandi,
.btn-dandi:hover {
    border-radius: 8px;
    color: white;
    background-color: var(--blue);
    border: 3px solid var(--blue);
    text-transform: uppercase;
    font-weight: 700;
    min-height: 50px;
}

.btn-small-outline-dandi,
.btn-small-outline-dandi:hover {
    border-radius: 8px;
    color: var(--blue);
    border: 1px solid var(--blue);
    text-transform: uppercase;
    font-weight: 300;
}

input,
textarea {
    border-radius: 8px;
    color: var(--blue);
    border: 3px solid var(--blue);
    text-transform: uppercase;
    font-weight: 700;
    min-height: 50px;
}

#chartdiv {
    height: 800px;
}