.profile .profile-title .logo {
    height: 80px;
    width: auto;
}

.face-plot .wrapper {
    height: 100px;
}

.highlighted {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
}

.ethnicity-block {
    position: relative;
    height: 150px;
}

.ethnicity-block .indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ethnicity-block .indicator-value {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0%;
    margin-top: -15px;
}