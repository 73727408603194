.dashboard {
    background-color: #F8FAFF;
}

.dashboard .dashboard-header {
    height: 60px;
    overflow: hidden;
    z-index: 999999;
}

.dashboard .dashboard-header .logo {
    height: 50%;
}

.dashboard .dashboard-header .logo img {
    height: 100%;
}

.dashboard .dashboard-header .menu-item {
    height: 50%;
}

.dashboard .block-1 {
    background-image: url("../../assets/images/background.png");
    background-repeat: no-repeat;
    background-size: 60% auto;
    background-position: 0% 50%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.dashboard .block-2, .dashboard .header {
    position: relative;
    z-index: 10000;
}

.dashboard .block-2 {
    min-height: calc(100vh - 60px);
}

.dashboard .main-title {
    font-size: 35px;
    font-weight: 500;
}

.dashboard .dandi-card {
    -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.18);
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.dashboard .dandi-card .logo {
    width: 62px;
}

.dashboard .dandi-card .title {
    font-size: 25px;
    font-weight: 500;
}

.dashboard .dandi-card .value {
    font-size: 25px;
}

.dashboard .dandi-card .dots-menu {
    position: absolute;
    top: 15px;
    right: 20px;
}

.dashboard .dandi-card .dots-menu img {
    height: 20px;
    width: auto;
}

.dashboard .dandi-card .dots-menu-on-hover-li {
    text-align: end;
}

.dashboard .footer {
    background-color: white;
    position: relative;
}

.dashboard .footer img {
    height: 30px;
}

.dashboard .footer .link {
    font-size: 12px;
}

.dashboard .popup-container {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000000;
    position: absolute;
}

.dashboard .popup-container .popup {
    background-color: white;
    width: 500px;
}