.home-header {
    height: 80px;
    overflow: hidden;
    position: relative;
    top: 0;
}

.home-header img {
    height: 70%;
    width: auto;
}

.home-header a {
    height: 100%;
    width: auto;
}

.home-header .menu-item {
    height: 50%;
}