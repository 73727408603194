.vertical-bar {
    max-width: 70px;
    min-width: 10px;
    width: 25px;
    height: 200px;
}

.vertical-bar .bg-light {
    background-color: #fafafa !important;
}

.vertical-bar-label {
    writing-mode: vertical-lr;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.vertical-bar-lower, .vertical-bar-upper {
    font-size: 12px;
    border-radius: 10px;
}

.vertical-bar-lower {
    background-color: #E3B1F1;
    border: 1px solid #B331DD;
}

.vertical-bar-upper {
    background-color: #AEE4F1;
    border: 1px solid #226A84;
}

.vertical-bar .label {
    font-size: 14px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    line-height: 30px;
}

.label.azzurro {
    color: #226A84;
}

.label.pink {
    color: #B331DD;
}