.age-bar {
    overflow: hidden;
}

.age-bar .age-bar-label {
    height: 100%;
}

.age-bar-bar {
    height: 7px;
    overflow: hidden;
    border-radius: 50px;
    position: relative;
}

.age-bar-bar .age-bar-background {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    border-width: 0px;
    background-color: rgba(0, 0, 0, 0.2);
}

.age-bar-bar .age-bar-item {
    position: relative;
    top: -7px;
    border-radius: 50px;
    min-width: 1%;
}