.post-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background: white;
}

.post-image-horizontal {
    width: 100%;
    height: auto;
}

.post-image-vertical {
    width: auto;
    height: 100%;
}