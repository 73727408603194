.home {
    background-color: #F8FAFF;
}

.mt-6 {
    margin-top: 80px;
}

.home .home-header {
    height: 80px;
    overflow: hidden;
}

.home .home-header img {
    height: 70%;
    width: auto;
}

.home .home-header .menu-item {
    height: 50%;
}

.home .home-block-0, 
.home .home-block-2,
.home .home-block-3,
.home .home-block-5 {
    position: relative;
}

.home .home-block-1 {
    min-height: 100vh;
    position: relative;
    margin-top: -80px;
}

.home .home-block-1 .min-h-100 {
    min-height: 100vh;
}

.home .home-block-1 .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 70px;
}

.home .home-block-1 .subtitle {
    font-size: 24px;
    
}

.home .home-block-2 {
    background-color: var(--blue);
    height: auto;
    overflow: hidden;
    background-image: url("../../assets/images/mac\ for\ demo.png");
    background-position: 50%;
    background-size: 70% auto;
    background-repeat: no-repeat;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    height: 0;
    overflow: hidden;
}

.home .home-block-2.trailer iframe {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
}

.home .home-block-3 {
    min-height: 80vh;
}

.home .home-block-3 .title {
    font-size: 40px;
    font-weight: 500;
    color: var(--grey);
}

.home .home-block-3 .logo {
    height: 90px;
    width: auto;
}

.home .home-block-3 .benefit-title {
    font-size: 24px;
    font-weight: 700;
    color: var(--blue);
    margin-top: 25px;
}

.home .home-block-3 .benefit-text {
    font-size: 20px;
    font-weight: 300;
    color: var(--blue);
    margin-top: 15px;
}

.home .home-block-4 {
    overflow: hidden;
    position: relative;
    background-image: url("../../assets/images/start_analysis.png");
    background-position: cover;
}

.home .home-block-4 .title {
    font-size: 30px;
    font-weight: 700;
    color: var(--blue);
    margin-top: 100px;
}

.home .home-block-4 .text {
    font-size: 25px;
    font-weight: 400;
    color: var(--blue);
}

.home .home-block-4 .request-a-demo {
    margin-bottom: 100px;
}

.login-popup, .demo-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999999;
    display: none;
}

.login .logo, .demo .logo {
    height: 60px;
}

.login > .row, .demo > .row {
    max-width: 500px;
    width: 100%;
    background-color: white;
}

.login .overlay, .demo .overlay {
    position: absolute;
    z-index: 0;
}

.login .login-input, .demo .login-input {
    position: absolute;
    z-index: 9999;
}

.home .home-block-6 {
    background-color: var(--blue);
}